/*--------------------------------------------------------------*/
/*  General   */
/*--------------------------------------------------------------*/
@charset "UTF-8";
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://fonts.googleapis.com/css2?family=Ewert&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sancreek&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dynalight&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Londrina+Shadow&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Libre+Barcode+128+Text&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jacques+Francois+Shadow&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Libre+Barcode+39+Extended&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Antonio:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inria+Serif:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Newsreader:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/*--------------------------------------------------------------*/
* {
  margin: 0px;
  padding: 0px;
  outline: none !important;
  box-sizing: border-box;
}

* a {
  text-decoration: none;
}

* a:hover,
a:focus {
  cursor: pointer;
  text-decoration: none;
}

.clickable {
  cursor: pointer;
}

html,
body {
  margin: 0px;
  padding: 0px;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  outline: none !important;
  font-family: "Open Sans", sans-serif !important;

  --webkit-text-size-adjust: 100%;
  --webkit-touch-callout: none;
  --webkit-user-select: none;
  --khtml-user-select: none;
  --moz-user-select: none;
  --ms-user-select: none;
  user-select: none;
}

header {
  overflow: hidden;
}

.page-title {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  transition: all 0.2s ease 0s;
}

.page-title-text {
  line-height: 1.2;
  font-weight: 600;
  font-style: italic;
  font-family: "Georgia", sans-serif;
  transition: all 0.2s ease 0s;
}

.main {
  width: 100%;
  height: 100%;
}

.product {
  width: 100%;
  height: 100%;
  min-height: 100%;
}

.product-title {
  width: 100%;
}

.product-title-text {
  width: 100%;
  padding: 20px;
  line-height: 1.2;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease 0s;
}

.product-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.product-content-brand {
  padding: 10px;
  animation: sequence-entry 0.5s ease-in;
}

@keyframes sequence-entry {
  0% {
    margin-top: 100px;
    opacity: 0;
  }

  100% {
    margin-top: 0px;
    opacity: 100;
  }
}

.product-content-brand .brand-pic {
  width: 100%;
}

.product-content-brand .brand-pic img {
  width: 100%;
  height: 100%;
}

.product-content-brand p {
  width: 100%;
  padding: 5px 0px;
  font-size: 20px;
  line-height: 1.2;
  font-weight: 600;
  white-space: nowrap;
  font-family: "Open Sans", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-content-brand .brand-links {
  width: 100%;
  padding: 5px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-content-brand .item-icon {
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-content-brand .item-icon span {
  margin: 0px 4px;

  font-size: 20px;
}

.tools-content {
  display: flex;
  align-items: center;
}

.tools-content-item {
  width: 100%;
}

.tools-content-item p {
  width: 100%;
  margin: 0px !important;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.4rem;
  white-space: nowrap;
  box-sizing: border-box;
  border: 1px solid rgb(0 100 150);
  display: flex;
  align-items: center;
  justify-content: center;
}

footer {
  width: 100%;
  position: relative;
}

footer .container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-text {
  line-height: 1;
  padding: 15px;
  font-size: 14px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
}

::-webkit-scrollbar {
  width: 1px;
  height: 1px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #808080;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #808080;
}